import React, { Component } from 'react';

class ContactEn extends Component {
  constructor(props) {
    super(props);
    this.state = { message: "none" };
  }

  inactiveMessage = (event) => {
    event.preventDefault();
    this.setState(
      () => ({
        message: "none",
      })
    )
  }

  activeMessage = (event) => {
    event.preventDefault();
    this.setState(
      () => ({
        message: "",
      })
    )
  }

  render() {
    return (
			<div>
		    <div className="is-floating-form" style={{ display: this.state.message }}>
		      <div className="is-floating-form-wrapper">
		        <div className="contact-us-form container w-container">
              <form action="https://formspree.io/xqkzwzgb" method="POST">
                <div className="contact-input-row">
                  <div className="w-col w-col-6">
                    <input className="text-field w-input" placeholder='First Name' type='text' name='first' />
                  </div>
                  <div className="w-col w-col-6">
                    <input className="text-field w-input" placeholder='Last Name' type='text' name='last' />
                  </div>
                </div>
                <div className="contact-input-row">
                  <div className="w-col w-col-6">
                    <input className="text-field w-input" placeholder='Email' type='email' name='email' />
                  </div>
                  <div className="w-col w-col-6">
                    <input className="text-field w-input" placeholder='Phone' type='text' name='phone' />
                  </div>
                </div>
                <div className="contact-input-row">
                  <div className="w-col w-col-6">
                    <input className="text-field w-input" placeholder='Company' type='text' name='company' />
                  </div>
                  <div className="w-col w-col-6">
                    <input className="text-field w-input" placeholder='Position' type='text' name='position' />
                  </div>
                </div>
                <input className="text-field w-input" placeholder='Country' type='text' name='country' />
                <textarea className="text-field area w-input" label='Message' name="message" type='text' placeholder='Ask us anything...' />
                <button className="button" type="submit">Submit</button>
              </form>
		        </div>
		      </div>
		    </div>
		    <button className="contact-button" onClick={this.state.message === "" ? this.inactiveMessage : this.activeMessage}>
          <div className="contact-link-text">CONTACT US</div>
		    </button>
		  </div>
		)
	}
}

export default ContactEn;
  