import React from 'react';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';
import Layout from '../components/layout';
import ReactMarkdown from 'react-markdown';
import Typical from 'react-typical'
import ScrollAnimation from 'react-animate-on-scroll';
import ReactPlayer from 'react-player';
import Header from '../components/headerEn';
import Footer from '../components/footerEn';
import Contact from '../components/contactEn';
import SEO from "../components/seo";


const steps = [
  'No Gap.', 1000,
  'No Overlap.', 1000,
  'No Shadow.', 1500,
];

class TruseamlessEnPage extends React.Component {
  constructor(props) {
    super(props);
    this.state ={
      cover: true,
      targetVideo: "",
      typing: true,
    }
  }

  done = () => {
    setTimeout(function() {
      this.setState({ typing: false }, () => {
        this.setState({ typing: true })
      });
    }.bind(this), 800)
  }

  hoverOn = (id) => {
    console.log("mouse over" + id);
    this.setState({
      cover: false,
      targetVideo: id,
    })
  }

  hoverOff = (id) => {
    console.log("mouse off" + id);
    this.setState({
      cover: true,
      targetVideo: id,
    })
  }

  render() {
    const { data } = this.props;
    return (
      <Layout>
        <SEO title="Tru-Seamless" />
        <Header />
        <Contact />
        <section>
          <div className="video-banner">
            <video 
              autoPlay
              muted
              loop
              style={{ height:"100vh", width:"100%", objectFit:"cover", position:"absolute" }}
            >
              <source src="/static/videos/TSL_background_compressed_hrjfux.mp4" type="video/mp4"></source>
            </video>
            <div className="banner-second-main" style={{ color:"white"}}>
              <Typical wrapper="h1" steps={steps} loop={Infinity} />
            </div>
          </div>
        </section>

        <div className="page-content">
          <section className="section">
            <div className="container w-container">
              <div className="w-row">
                <div className="w-col w-col-12 w-col-stack">
                  {data.allStrapiTruseamless.edges.map(document => (
                  <div key={document.node.id}> 
                    {(document.node.language === "English") ?
                    <div className="w-dyn-items" >
                      <ScrollAnimation animateIn='fadeInUp' animateOnce={true}>
                        <ReactMarkdown source={document.node.description} />
                      </ScrollAnimation>
                    </div> : <span />}
                  </div>
                  ))}
                </div>
              </div>
            </div>
          </section>

          <section className="section more-padding" style={{ background:"#232323"}}>
            <div className="container w-container">
              <ScrollAnimation animateIn='fadeInUp' animateOnce={true}>
                <div style={{ marginBottom: "10em" }}>
                  <h2 className="invert-text-color">Welcome to <br />the world of Tru-Seamless</h2>
                  <h3>Experience Tru-Seamless patterns live, breath, and dream.</h3>
                </div>
              </ScrollAnimation>
              <h3 className="invert-text-color product-image-title">Holographics</h3>
              {data.allStrapiMotion.edges.map(document => (
              <div key={document.node.id}>
              {(document.node.label === "Holographic") ?
                <div className="w-col w-col-3 w-col-medium-4 w-col-small-6" style={{ paddingTop: "10px", paddingBottom: "10px", minHeight:"360px" }} >
                  <div onMouseEnter={() => this.hoverOn(document.node.id)} onMouseLeave={() => this.hoverOff(document.node.id)}>
                    {document.node.video_url ? (
                      <ReactPlayer
                        url={document.node.video_url.replace("/upload/", "/upload/c_scale,h_250,q_auto:best,w_250/")}
                        playing
                        loop
                        muted
                        width='215px'
                        height='215px'
                        preloading="true"
                        playbackRate={2}
                        className="product-video"
                      />
                    ) : (
                      <div>
                        <Img fluid={document.node.preview.childImageSharp.fluid} />
                      </div>
                    )}
                    { this.state.cover === false && this.state.targetVideo === document.node.id 
                      ? <div className="video-cover" style={{ visibility:"hidden", opacity: 0 }}> 
                          <Img fluid={document.node.preview.childImageSharp.fluid} />
                        </div>
                      : <div className="video-cover">
                          <Img fluid={document.node.preview.childImageSharp.fluid} />
                        </div>
                    }
                  </div>
                  <div className="img-description">
                    <p className="event-office-text">{document.node.name}</p>
                  </div>
                </div> : <span /> }
              </div>
              ))}
            </div>
          </section>

          <section style={{ background:"#232323", paddingBottom: 140 }}>
            <div className="container w-container">
              <h3 className="invert-text-color product-image-title">Fresnel Lens</h3>
              {data.allStrapiMotion.edges.map(document => (
              <div key={document.node.id}>
              {(document.node.label === "Fresnels") ?
                <div className="w-col w-col-3 w-col-medium-4 w-col-small-6" style={{ paddingTop: "10px", paddingBottom: "10px", minHeight:"360px" }} >
                  <div onMouseEnter={() => this.hoverOn(document.node.id)} onMouseLeave={() => this.hoverOff(document.node.id)}>
                    <ReactPlayer
                      url={document.node.video_url.replace("/upload/", "/upload/c_scale,h_250,q_auto:best,w_250/")}
                      playing
                      loop
                      muted
                      width='215px'
                      height='215px'
                      preloading="true"
                      playbackRate={2}
                      className="product-video"
                    />
                    { this.state.cover === false && this.state.targetVideo === document.node.id 
                      ? <div className="video-cover" style={{ visibility:"hidden", opacity: 0 }}> 
                          <Img fluid={document.node.preview.childImageSharp.fluid} />
                        </div>
                      : <div className="video-cover">
                          <Img fluid={document.node.preview.childImageSharp.fluid} />
                        </div>
                    }
                  </div>
                  <div className="img-description">
                    <p className="event-office-text">{document.node.name}</p>
                  </div>
                </div> : <span /> }
              </div>
              ))}
            </div>
          </section>
        </div>
        <Footer />
      </Layout>
    )
  }
}
export default TruseamlessEnPage;

export const pageQuery = graphql`  
  query TruseamlessEnQuery {
    allStrapiTruseamless {
      edges {
        node {
          id
          description
          language
        }
      }
    }
    allStrapiMotion (sort: {fields: name, order: ASC}) {
      edges {
        node {
          id
          name
          video_url
          label
          preview {
            childImageSharp {
              fluid(maxWidth: 250) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  }
`
