import { Link } from "gatsby";
import PropTypes from "prop-types";
import React from "react";
import { Location } from '@reach/router';
import { HamburgerSpin } from 'react-animated-burgers';
import Logo from '../images/klaser_logo.svg';

class HeaderEn extends React.Component {
  static propTypes = {
    location: PropTypes.object.isRequired
  }
  constructor(props) {
    super(props);
    this.state ={
      isActive: false
    }
  }
  componentDidMount() {
    window.addEventListener("scroll", this.resizeHeaderOnScroll);
  }
  resizeHeaderOnScroll() {
    const distanceY = window.pageYOffset || document.documentElement.scrollTop,
      shrinkOn = 100,
      headerEl = document.getElementById("header"),
      logoEl = document.getElementById("logo")

    if (distanceY > shrinkOn) {
      headerEl.classList.add("smaller");
      logoEl.classList.add("smaller")
    } else {
      headerEl.classList.remove("smaller");
      logoEl.classList.remove("smaller");
    }
  }
  toggleButton = () => {
    this.setState({
      isActive: !this.state.isActive
    })
  }

  render() {
    const { location } = this.props;
    return (
      <header>
        <div className="navigation-wrapper">
          <div className="navigation" id="header">
            <div className="container in-navigation w-container">
              <div className="navigation-left">
                <Link to="/en/" id="logo" className="logo-block w-inline-block w--current">
                  <div className="logo-text w-hidden-medium w-hidden-small w-hidden-tiny"><img src={Logo} alt="" style={{ width:95 }} /></div>
                </Link>
              </div>
              <div className="navigation-left site-header">
                <Link to="/en/about" activeStyle={{ color: "white", backgroundColor:"#3499ff" }} className="nav-item w-hidden-medium w-hidden-small w-hidden-tiny w-inline-block ">
                  About
                </Link>
              </div>
              <div className="navigation-left site-header">
                <Link to="/en/truseamless" activeStyle={{ color: "white", backgroundColor:"#3499ff" }} className="nav-item w-hidden-medium w-hidden-small w-hidden-tiny w-inline-block ">
                  Tru-Seamless
                </Link>
              </div>
              <div className="navigation-left site-header">
                <Link to="/en/products" activeStyle={{ color: "white", backgroundColor:"#3499ff" }} className="nav-item w-hidden-medium w-hidden-small w-hidden-tiny w-inline-block ">
                  Products
                </Link>
              </div>
              <div className="navigation-left site-header">
                <Link to="/en/services" activeStyle={{ color: "white", backgroundColor:"#3499ff" }} className="nav-item w-hidden-medium w-hidden-small w-hidden-tiny w-inline-block ">
                  Services
                </Link>
              </div>
              <div className="navigation-left site-header">
                <Link to="/en/events" activeStyle={{ color: "white", backgroundColor:"#3499ff" }} className="nav-item w-hidden-medium w-hidden-small w-hidden-tiny w-inline-block ">
                  Events
                </Link>
              </div>
              <div className="navigation-left site-header">
                <Link to="/en/investor" activeStyle={{ color: "white", backgroundColor:"#3499ff" }} className="nav-item w-hidden-medium w-hidden-small w-hidden-tiny w-inline-block ">
                  Investors
                </Link>
              </div>
              <div className="navigation-left site-header">
                <Link to="/en/inventory" activeStyle={{ color: "white", backgroundColor:"#3499ff" }} className="nav-item w-hidden-medium w-hidden-small w-hidden-tiny w-inline-block ">
                  Login
                </Link>
              </div>
              <div className="navigation-left site-header">
                <Link to={`${location.pathname.substr(3)}`}className="logo-block w-inline-block w--current">
                  <div className="nav-item">中文</div>
                </Link>
              </div>
              <div className="mobile-only">
                <img src={Logo} alt=""  style={{ width:65, margin:"75%" }} />
              </div>
              <div className="navigation-right mobile-only">
                <HamburgerSpin style={{ outline:"none" }} isActive={this.state.isActive} toggleButton={this.toggleButton} barColor="black" />
              </div>
            </div>
          </div>
          <div className={ this.state.isActive ? "navigation-content-active" : "navigation-content"}>
            <div className="container center w-container">
              <div className="navigation-block _1">
                <Link to="/en/" className="navigation-link w-inline-block">
                  <h1 className="nav-text">Home</h1>
                </Link>
              </div>
              <div className="navigation-block _2">
                <Link to="/en/about" className="navigation-link w-inline-block">
                  <h1 className="nav-text">About</h1>
                  <div className="navigation-line"></div>
                </Link>
              </div>
              <div className="navigation-block _3">
                <Link to="/en/truseamless" className="navigation-link w-inline-block">
                  <h1 className="nav-text">Tru-Seamless</h1>
                  <div className="navigation-line"></div>
                </Link>
              </div>
              <div className="navigation-block _4">
                <Link to="/en/products" className="navigation-link w-inline-block">
                  <h1 className="nav-text">Products</h1>
                  <div className="navigation-line"></div>
                </Link>
              </div>
              <div className="navigation-block _5">
                <Link to="/en/services" className="navigation-link w-inline-block">
                  <h1 className="nav-text">Services</h1>
                  <div className="navigation-line"></div>
                </Link>
              </div>
              <div className="navigation-block _6">
                <Link to="/en/events" className="navigation-link w-inline-block">
                  <h1 className="nav-text">Events</h1>
                  <div className="navigation-line"></div>
                </Link>
              </div>
              <div className="navigation-block _6">
                <Link to="/en/investor" className="navigation-link w-inline-block">
                  <h1 className="nav-text">Investors</h1>
                  <div className="navigation-line"></div>
                </Link>
              </div>
              <div className="navigation-block _7">
                <Link to="/en/inventory" className="navigation-link w-inline-block">
                  <h1 className="nav-text">Login</h1>
                  <div className="navigation-line"></div>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </header>
    )
  }
}

export default props => (
  <Location>
    {locationProps => <HeaderEn {...locationProps} {...props} />}
  </Location>
);
