import { Link } from "gatsby";
import React from "react";
import Logo from '../images/klaser_logo.svg';

const FooterEn = () => (

  <div className="footer">
    <div className="container w-container">
      <div className="w-row">
        <div className="w-col w-col-5 w-col-stack">
          <div className="footer-wrapper">
            <a href="#" className="line-wrapper-second in-top w-inline-block">
              <div className="link-text-second">Go Top</div>
              <div className="link-line-second"></div>
            </a>
            <div className="footer-title">
              <h4 className="footer-sub-title">Social Media</h4>
            </div>
            <div className="top-margin medium">
              <div className="footer-social">
                <a href="https://www.instagram.com/klasergroup/" target="_blank" rel="noopener noreferrer" className="social-icon w-inline-block">
                  <i className="fab fa-instagram" style={{ color: "#232323", fontSize:30 }} />
                </a>
                <a href="https://www.facebook.com/KLASERTAIWAN" target="_blank" rel="noopener noreferrer" className="social-icon w-inline-block">
                  <i className="fab fa-facebook" style={{ color: "#232323", fontSize:30 }} />
                </a>                
                <a href="https://twitter.com/KLaserGroup" target="_blank" rel="noopener noreferrer" className="social-icon w-inline-block">
                  <i className="fab fa-twitter" style={{ color: "#232323", fontSize:30 }} />
                </a>
                <a href="https://www.behance.net/KLaserGroup" target="_blank" rel="noopener noreferrer" className="social-icon w-inline-block">
                  <i className="fab fa-behance" style={{ color: "#232323", fontSize:30 }} />
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="w-col w-col-5 w-col-stack">
          <div className="footer-wrapper">
            <div className="footer-title">
              <h4 className="footer-sub-title">Navigation</h4>
            </div>
            <div className="top-margin medium">
              <div>
                <Link to="/en/about/" className="line-wrapper-second second in-footer-nav w-inline-block">
                  <div className="link-text-second">About</div>
                  <div className="link-line-second"></div>
                </Link>
              </div>
              <div>
              <div>
                <Link to="/en/truseamless/" className="line-wrapper-second second in-footer-nav w-inline-block w--current">
                  <div className="link-text-second">Tru-Seamless</div>
                  <div className="link-line-second"></div>
                </Link>
              </div>
                <Link to="/en/products/" className="line-wrapper-second second in-footer-nav w-inline-block">
                  <div className="link-text-second">Products</div>
                  <div className="link-line-second"></div>
                </Link>
              </div>
              <div>
                <Link to="/en/services/" className="line-wrapper-second second in-footer-nav w-inline-block w--current">
                  <div className="link-text-second">Services</div>
                  <div className="link-line-second"></div>
                </Link>
              </div>
              <div>
                <Link to="/en/events/" className="line-wrapper-second second in-footer-nav w-inline-block w--current">
                  <div className="link-text-second">Events</div>
                  <div className="link-line-second"></div>
                </Link>
              </div>
              <div>
                <Link to="/en/investor/" className="line-wrapper-second second in-footer-nav w-inline-block w--current">
                  <div className="link-text-second">Investors</div>
                  <div className="link-line-second"></div>
                </Link>
              </div>
              <div>
                <Link to="/en/inventory/" className="line-wrapper-second second in-footer-nav w-inline-block w--current">
                  <div className="link-text-second">Login</div>
                  <div className="link-line-second"></div>
                </Link>
              </div>

            </div>
          </div>
        </div>
      </div>
      <div className="line-divider"></div>
      <div>
        <div className="flex-row make-normal w-row">
          <div className="w-col w-col-6 w-col-stack">
            <div>
              <p className="copyright">© {new Date().getFullYear()} K Laser Technology Inc.</p>
              <p style={{ fontSize:12, marginBottom: 0 }}>Klasergroup.com no longer supports Internet Explorer.</p>
              <p style={{ fontSize:12 }}>For the best viewing experience, update your browser to Chrome.</p>
            </div>
          </div>
          <div className="w-col w-col-6 w-col-stack">
            <div className="align-left make-with-margin">
              <Link to="/" className="footer-title w-inline-block"><img src={Logo} width="100" alt="logo" style={{ padding:"12px" }}/></Link>
              <div className="footer-title w-inline-block"><img src="/static/images/IHMA_logo_i1kdlt.png" width="100" alt="logo" style={{ padding:"12px" }}/></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
)

export default FooterEn;
